const epcRoutes = [
    {
        path: '/oepc',
        name: 'oepc',
        redirect: {
            name: 'oepc.auto-redirect',
        },
        children: [
            {
                path: 'referral',
                name: 'oepc.referral',
                component: () => import('./components/pages/OepcReferral.vue'),
                meta: {
                    public: true,
                },
            },
            {
                name: 'oepc.auto-redirect',
                path: 'redirect',
                component: () => import('./components/pages/OepcRedirect.vue'),
            },
            {
                name: 'oepc.custom-quote',
                path: 'custom-quote',
                component: () => import('./components/pages/OepcCustomQuote.vue'),
            },
            {
                name: 'oepc.request',
                path: ':action?',
                component: () => import('./components/pages/OepcLayout.vue'),
            },
        ],
    },
]

export default epcRoutes
