<template>
    <q-toolbar
        id="navbar"
        class="bg-white text-primary"
        :class="{ 'q-pl-none': $q.screen.lt.md }"
        v-bind="$attrs"
    >
        <re-button
            v-if="prevRoute"
            class="lt-md text-black"
            icon="mdi-chevron-left"
            flat
            size="md"
            :to="{
                name: prevRoute,
            }"
        />
        <p v-if="mobileTitle" class="text-black text-bold q-my-auto">
            {{ mobileTitle }}
        </p>
        <div v-if="!mobileTitle" class="q-mx-sm">
            <q-btn
                :to="{
                    name: curUser?.data.email ? 'appDashboard' : 'login',
                    query: { resetCurRequest: true },
                }"
                flat
                padding="0 .5em"
            >
                <img src="@/assets/icons/rockestate-logo-horizontal-dark.svg" class="logo" width="148" />
            </q-btn>
        </div>
        <q-btn
            v-if="props.hasSideMenu"
            class="lt-sm text-black q-ml-auto"
            :class="{ 'bg-blue-grey-2': menuOpen }"
            flat
            icon="mdi-menu"
            @click="menuOpen = !menuOpen"
        />

        <!-- TODO: svg icons mobile-menu${isMobileMenuOpen ? '-close' -->

        <template v-if="$q.screen.gt.xs">
            <q-space />
            <navbar-items />
        </template>
    </q-toolbar>
    <router-view v-if="$q.screen.lt.md" v-slot="{ Component }" name="navigation">
        <component :is="Component" :navbar-mode="true" />
    </router-view>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuasar } from 'quasar'

import NavbarItems from './NavbarItems.vue'

const menuOpen = defineModel('menuOpen', { type: Boolean, required: true })
const { t, te } = useI18n()
const $q = useQuasar()

const route = useRoute()
const curUser = inject('curUser')
// const config = inject('config')

const props = defineProps({
    hasSideMenu: {
        type: Boolean,
        default: false,
    },
})

// const appMapping = computed(() => ({
//     ers: 'ers',
//     avm: 'valuation.request',
//     dvm: 'valuation.request', // TODO: find a way for this to work when already on the valuation route
//     ovm: 'valuation.request',
// }))
const mobileTitle = computed(() => {
    if ($q.screen.gt.sm) return false
    let i18nParams = {}
    if (route.meta?.mobile?.title) {
        i18nParams[route.meta.mobile.title] = route.query[route.meta.mobile.title]
    }
    return te(route.meta?.mobile?.title) ? t(route.meta?.mobile?.title, { ...i18nParams }) : false
})

const prevRoute = computed(() => {
    return route.meta?.mobile?.prevRoute
})
// showMenu() {
//     return !$config.CHECK_LOGIN || curUser?.authenticated
// },

// fixed() {
//     document.body.classList.toggle('has-navbar-fixed-top', fixed)
// },
// '$route.name'() {
//     isMobileMenuOpen = false
// },

/*update_roles(roles) {
            patchUser({ roles: utils.listToRoles(roles) })
        },*/
// shakeAnimation() {
//     shake = true
// },
</script>

<style lang="scss" scoped>
#navbar {
    // padding: 0 15px;
    border-bottom: 1px solid #d6e2e9;
    @media print {
        display: none;
    }
}
</style>
