<template>
    <div></div>
</template>

<script>
import { loadScript, unloadScript } from 'vue-plugin-load-script'

export default {
    name: 'HubspotChat',
    inject: ['curUser'],
    data: () => ({
        scriptUrl: 'https://js-na1.hs-scripts.com/9112572.js',
        loaded: false,
    }),
    computed: {
        roles: function () {
            return this.curUser?.roles
        },
        hasRole: function () {
            return this.curUser?.hasRole
        },
    },
    watch: {
        roles: {
            handler() {
                if (this.$config.ENABLE_HUBSPOT && !this.loaded && this.hasRole('valuer', 'ovm')) {
                    this.load()
                } else if (this.loaded && !this.hasRole('valuer', 'ovm')) {
                    this.unload()
                }
            },
            immediate: true,
        },
    },
    methods: {
        load() {
            loadScript(this.scriptUrl).then(() => {
                this.loaded = true
            })
        },
        unload() {
            unloadScript(this.scriptUrl).then(() => {
                this.loaded = false
            })
        },
    },
}
</script>
