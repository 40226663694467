import { computed } from 'vue'

export default {
    install: (app, options) => {
        // en-BE does some rather strange things with formatting: using neutral 'en-GB' instead:
        const locale = computed(() =>
            options.locale.value.startsWith('en') || options.locale.value === 'technical'
                ? 'en-GB'
                : options.locale.value
        )

        const formatDate = (date, options) => {
            // TODO: add i18n for this:
            if (!date) return 'N/A'
            const dateObj = new Date(date)
            if (isNaN(dateObj)) return 'N/A'
            return dateObj.toLocaleDateString(locale.value, options)
        }

        // inject a globally available $format object
        app.config.globalProperties.$format = {
            date: (date) => {
                return formatDate(date)
            },

            dateLong: (date, fmtOptions = { weekday: true }) => {
                return formatDate(date, {
                    weekday: fmtOptions.weekday ? 'long' : undefined,
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })
            },

            datetime: (date) => {
                return new Date(date).toLocaleDateString(locale.value, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                })
            },

            datetimeLong: (date) => {
                return new Date(date).toLocaleDateString(locale.value, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                })
            },

            time: (date) => {
                return new Date(date).toLocaleString(locale.value, {
                    hour: 'numeric',
                    minute: 'numeric',
                })
            },

            currency: (number, { showNa = true, showCents = false, ...fmtOptions } = {}) => {
                if ((number === undefined || number === null) && showNa) return 'N/A'
                if (fmtOptions.nearest === undefined) {
                    if (number >= 10000) fmtOptions.nearest = 1000
                    else if (number > 1000) fmtOptions.nearest = 10
                }
                return new Intl.NumberFormat(locale.value, {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: fmtOptions.nearest ? 0 : showCents,
                    ...fmtOptions,
                }).format(
                    fmtOptions.nearest ? Math.round(number / fmtOptions.nearest) * fmtOptions.nearest : number
                )
            },

            number: (number, fmtOptions = {}) => {
                return new Intl.NumberFormat(locale.value, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    ...fmtOptions,
                }).format(number)
            },

            integer: (number, fmtOptions = {}) => {
                return new Intl.NumberFormat(locale.value, {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    ...fmtOptions,
                }).format(number)
            },

            round: (number, fmtOptions = { nearest: 1000 }) => {
                return new Intl.NumberFormat(locale.value, {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    ...fmtOptions,
                }).format(Math.round(number / fmtOptions.nearest) * fmtOptions.nearest)
            },
        }
    },
}
