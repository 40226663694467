<template>
    <div class="row items-center no-wrap full-width">
        <span v-if="isRangeLabels" class="text-body1 text-caption">
            {{ $attrs['marker-labels'][0].label }}
        </span>
        <q-slider
            v-model="model"
            :class="{ 'no-pointer-events': isIndicator }"
            v-bind="{ ...graphAttrs, ...$attrs }"
            :marker-labels-class="isRangeLabels ? 'hidden' : $attrs['marker-labels-class']"
            class="q-mx-md col-grow"
        />
        <span v-if="isRangeLabels" class="text-body1 text-caption">
            {{ $attrs['marker-labels'][1].label }}
        </span>
    </div>
</template>

<script setup>
import { computed, useAttrs } from 'vue'
const model = defineModel({ type: Number })
const props = defineProps({
    isIndicator: {
        type: Boolean,
        default: false,
    },
})

const attrs = useAttrs()

const isRangeLabels = computed(() => attrs['marker-labels'] && attrs['marker-labels'].length === 2)

const graphAttrs = computed(() => {
    return props.isIndicator
        ? {
              'label-always': true,
              min: 0,
              max: 100,
              'track-size': '0',
              'thumb-size': '0',
              dense: true,
          }
        : {}
})
</script>
