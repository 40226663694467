<template>
    <div class="title">
        <!-- TODO: figure out a way to get vertical alignment *and* not push tooltip to next line: -->
        <!-- class="row items-center" -->
        <component
            :is="`h${props.level}`"
            :class="{
                'q-mb-none q-pb-none': props.markdownPopup || props.popup || props.subtitle,
            }"
        >
            <re-icon
                v-if="props.iconSvgName"
                :svg-name="props.iconSvgName"
                color="secondary"
                class="q-mr-sm"
                size="sm"
            />
            <slot></slot>
            <re-tooltip
                v-if="props.tooltip || $slots.tooltip"
                :is-markdown="props.markdownTooltip"
                :label="props.tooltip"
                is-smaller
                direction="right"
            >
                <template v-if="$slots.tooltip" #content>
                    <slot name="tooltip" />
                </template>
            </re-tooltip>
        </component>
        <p v-if="props.subtitle" class="q-my-md text-body1">{{ props.subtitle }}</p>
        <div v-if="props.markdownPopup || props.popup" class="q-mb-md">
            <a class="text-bold" @click="isShowingPopup = true">
                {{ $t('energy.shared.tooltip_more_info') }}
            </a>
            <re-dialog v-model="isShowingPopup" has-close-button class="z-max">
                <vue-markdown v-if="props.markdownPopup" :source="props.markdownPopup" />
                <p v-else>{{ props.popup }}</p>
            </re-dialog>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import VueMarkdown from 'vue-markdown-render'

const props = defineProps({
    tooltip: {
        type: String,
        default: undefined,
    },
    markdownTooltip: {
        type: Boolean,
        default: false,
    },
    level: {
        type: Number,
        default: 1,
        validator(value) {
            return value >= 1 && value <= 6
        },
    },
    subtitle: {
        type: String,
        default: undefined,
    },
    popup: {
        type: String,
        default: undefined,
    },
    markdownPopup: {
        type: String,
        default: undefined,
    },
    iconSvgName: {
        type: String,
        default: undefined,
    },
})

const isShowingPopup = ref(false)
</script>
