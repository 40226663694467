<template>
    <q-dialog
        transition-show="scale"
        transition-hide="scale"
        aria-role="dialog"
        aria-modal
        v-bind="$attrs"
        backdrop-filter="blur(3px)"
        :maximized="$q.screen.xs"
    >
        <q-card :class="{ 'wide-dialog': wideDialog, 're-dialog': true }" class="column no-wrap">
            <q-card-section v-if="hasCloseButton || title" class="column q-pa-none justify-between">
                <div class="row justify-between bg-grey-2">
                    <slot name="title">
                        <h5 class="q-pa-lg col-auto">{{ title }}</h5>
                    </slot>
                    <q-btn
                        v-if="hasCloseButton"
                        v-close-popup
                        icon="mdi-close"
                        size="xs"
                        flat
                        round
                        outline
                        class="text-black bg-transparent self-center q-mr-lg bg-grey-4"
                    />
                </div>
                <q-separator />
            </q-card-section>
            <q-card-section class="main-content">
                <div class="q-pa-md column justify-center">
                    <slot></slot>
                </div>
            </q-card-section>
            <template v-if="hasCancel || hasConfirm || onCancel || onConfirm || $slots.actions">
                <q-separator />
                <q-card-section>
                    <!-- TODO: should we be using q-card-actions? -->
                    <div
                        :class="[$q.screen.xs ? 'column reverse' : 'row']"
                        class="q-gutter-x-md q-gutter-y-sm justify-end xs-btn-full-width"
                    >
                        <re-button
                            v-if="hasCancel || onCancel"
                            v-close-popup
                            size="md"
                            :label="cancelLabel || $t('monolith.shared.cancel')"
                            @click="emit('cancel')"
                        />
                        <slot name="actions"></slot>
                        <re-button
                            v-if="hasConfirm || onConfirm"
                            v-close-popup
                            type="submit"
                            color="main"
                            size="md"
                            :label="confirmLabel || $t('global.action.confirm')"
                            @click="emit('confirm')"
                        />
                    </div>
                </q-card-section>
            </template>
        </q-card>
    </q-dialog>
</template>
<script setup>
import { useQuasar } from 'quasar'

const $q = useQuasar()
defineProps({
    title: {
        type: String,
        default: undefined,
    },
    hasCancel: {
        type: Boolean,
        default: false,
    },
    hasConfirm: {
        type: Boolean,
        default: false,
    },
    hasCloseButton: {
        type: Boolean,
        default: false,
    },
    onCancel: {
        type: Function,
        default: undefined,
    },
    cancelLabel: {
        type: String,
        default: undefined,
    },
    onConfirm: {
        type: Function,
        default: undefined,
    },
    confirmLabel: {
        type: String,
        default: undefined,
    },
    wideDialog: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['cancel', 'confirm'])
</script>

<style scoped lang="scss">
.wide-dialog {
    max-width: 950px;
}

.re-dialog {
    min-width: 480px;

    .main-content {
        height: 100%;
        // max-height: 70vh;
        overflow: auto;
    }
}

body.screen--xs .re-dialog {
    min-width: inherit;
}
</style>
