import { computed, reactive } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { reactiveAny } from './utils/reactive'

// TODO: merge with useUser (after refactoring backend endpoint)
export default function useUserList(params = {}) {
    const queryKey = computed(() => ['valuation', 'users', params])

    const query = useQuery({
        queryKey: queryKey,
        placeholderData: [],
    })

    return reactive({
        isBusy: reactiveAny(query.isLoading, query.isFetching),
        queryKey,
        ...query,
    })
}

// TODO: add support for valuer list
// load_valuers() {
//     let url = utils.urlJoin(this.$config.VALUATION_API_URL, 'users')
//     let params = { roles: 'valuer', modules: 'dvm' }
//     axios.get(url, { params }).then((result) => {
//         this.valuers.dvm = result.data
//         this.valuers.dvm.sort((a, b) => {
//             if (a.email < b.email) return -1
//             if (a.email > b.email) return 1
//             return 0
//         })
//     })
//     params = { roles: 'valuer', modules: 'ovm' }
//     axios.get(url, { params }).then((result) => {
//         this.valuers.ovm = result.data
//         this.valuers.ovm.sort((a, b) => {
//             if (a.email < b.email) return -1
//             if (a.email > b.email) return 1
//             return 0
//         })
//     })
// },
