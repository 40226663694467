<template>
    <re-page max-width="xl" page-title="Configuration">
        <re-button
            class="col-xs-4 col-sm-auto"
            :color="isOnline ? 'main' : 'negative'"
            :label="isOnline ? 'online' : 'offline'"
            @click="setOnline(!isOnline)"
        />
        <!-- input section -->
        <div class="row re-mt">
            <re-button
                :disable="$config.isDefault"
                class="col-3"
                label="Reset to Default"
                :color="$config.isDefault ? 'grey' : 'primary'"
                icon="mdi-restore"
                icon-right
                aria-label="Reset to Default"
                @click="resetConfig"
            />
        </div>
        <div class="row re-mt">
            <re-button
                class="col-3"
                label="Fetch config from"
                color="main"
                icon="mdi-cloud-download"
                icon-right
                aria-label="Fetch config from"
                :disable="!configURL"
                @click="fetchConfig()"
            />
            <re-select
                v-model="configURL"
                class="col"
                use-input
                :options="[
                    'https://ing.rock.estate',
                    'https://argenta.rock.estate',
                    'https://val.rock.estate',
                    'https://avm-dev.rock.estate',
                    'https://refactor-dev.rock.estate',
                ]"
                new-value-mode="add-unique"
            />
        </div>
        <div class="re-block-mt column re-gutter">
            <div v-for="key in configKeys" :key="key">
                <div v-if="keyType(key) == 'boolean'">
                    <div :class="hasDefaultValue(key) ? '' : 'text-primary text-bold'">{{ key }}</div>
                    <re-multi-choice
                        v-model="$config[key]"
                        :readonly="false"
                        is-yes-no
                        :aria-label="$config[key]"
                        :color="hasDefaultValue(key) ? 'grey' : 'primary'"
                    ></re-multi-choice>
                </div>
                <div v-else-if="keyType(key) == 'string' || keyType(key) == 'number'">
                    <re-input
                        v-model="$config[key]"
                        :readonly="false"
                        :color="hasDefaultValue(key) ? 'grey' : 'primary'"
                    >
                        <template #label>
                            <div :class="hasDefaultValue(key) ? '' : 'text-primary text-bold'">{{ key }}</div>
                        </template>
                    </re-input>
                </div>
                <div v-else-if="keyType(key) == 'array'">
                    <div :class="hasDefaultValue(key) ? '' : 'text-primary text-bold'">{{ key }}</div>
                    <re-select
                        v-model="$config[key]"
                        filled
                        multiple
                        use-input
                        use-chips
                        :color="hasDefaultValue(key) ? '' : 'primary'"
                        :options="optionsFor(key)"
                        :readonly="false"
                        new-value-mode="add-unique"
                    />
                </div>
                <div v-else>{{ key }} ({{ keyType(key) }}): {{ $config[key] }}</div>
            </div>
        </div>
        <!-- table section -->
    </re-page>
</template>

<script>
import axios from '@/shared/plugins/axios.js'
import utils from '@/shared/plugins/utils'
import { useOnline } from '@/composables/utils/online'

export default {
    name: 'Configuration',
    setup() {
        const { isOnline, setOnline } = useOnline()
        return {
            isOnline,
            setOnline,
        }
    },
    data() {
        return {
            configURL: '',
        }
    },
    computed: {
        configKeys() {
            return Object.keys(this.$config._defaultConfig)
        },
        localConfig() {
            const localConfig = {}
            for (const key in this.$config._defaultConfig) {
                if (JSON.stringify(this.$config._defaultConfig[key]) !== JSON.stringify(this.$config[key])) {
                    localConfig[key] = this.$config[key]
                }
            }
            return localConfig
        },
    },
    watch: {
        localConfig: {
            handler: function (val) {
                localStorage.setItem('re-local-config', JSON.stringify(val))
                this.$config.isDefault = Object.keys(val).length === 0
            },
            deep: true,
        },
    },
    methods: {
        keyType(key) {
            const val = this.$config._defaultConfig[key]
            const basicType = typeof val
            if (basicType === 'object') {
                return Array.isArray(val) ? 'array' : 'object'
            } else {
                return basicType
            }
        },
        resetConfig() {
            for (const key in this.$config._defaultConfig) {
                this.$config[key] = this.$config._defaultConfig[key]
            }
        },
        optionsFor(key) {
            let baseOptions = []
            if (key === 'CHECK_FLOOD_FOR') {
                baseOptions = ['avm', 'dvm', 'ovm']
            } else if (key === 'FLOOD_EXTRA_ALLOWED_BUILDING_TYPES') {
                baseOptions = this.$config._defaultConfig.ALLOWED_BUILDING_TYPES
            } else if (key === 'REPORT_VISIBLE') {
                baseOptions = [
                    'avm_web',
                    'avm_pdf',
                    'dvm_web',
                    'dvm_pdf',
                    'ovm_web',
                    'ovm_pdf',
                    'oepc_pdf',
                    'flood_web',
                    'flood_pdf',
                ]
            } else {
                baseOptions = this.$config._defaultConfig[key]
            }
            return [...baseOptions, ...this.$config[key].filter((item) => !baseOptions.includes(item))]
        },
        hasDefaultValue(key) {
            return JSON.stringify(this.$config[key]) === JSON.stringify(this.$config._defaultConfig[key])
        },
        fetchConfig() {
            return axios
                .get(utils.urlJoin(this.configURL, 're_info/frontend_settings'))
                .then((response) => {
                    for (const key in response.data) {
                        this.$config[key] = response.data[key]
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        },
    },
}
</script>
