import { matchQuery } from '@tanstack/query-core'
import { hashKey } from '@tanstack/vue-query'
import dconsole from '@/shared/plugins/debug_console'
import store from '@/store'
import { onlineManager } from '@tanstack/vue-query'

export const PERSISTER_KEY_PREFIX = 'RE-query'

export function makePersisterStorageKey({ queryKey, verb, prefix }) {
    const curUserId = store.getters.getCurUser?.userId || 'anon'

    return (
        `${PERSISTER_KEY_PREFIX}-${curUserId}` +
        (prefix ? `-${prefix}` : '') +
        (verb ? `-${verb}` : '') +
        (queryKey ? `-${hashKey(queryKey)}` : '')
    )
}

// Copy-paste of code from node_modules/@tanstack/query-persist-client-core:

/**
 * Warning: experimental feature.
 * This utility function enables fine-grained query persistance.
 * Simple add it as a `persister` parameter to `useQuery` or `defaultOptions` on `queryClient`.
 *
 * ```
 * useQuery({
     queryKey: ['myKey'],
     queryFn: fetcher,
     persister: createPersister({
       storage: localStorage,
     }),
   })
   ```
 */
export function experimental_createPersister({
    storage,
    buster = '',
    maxAge = 1000 * 60 * 60 * 24,
    serialize = JSON.stringify,
    deserialize = JSON.parse,
    filters,
}) {
    return async function persisterFn(queryFn, context, query) {
        const storageKey = makePersisterStorageKey({ queryKey: query.queryKey })
        const matchesFilter = filters ? matchQuery(filters, query) : true

        dconsole.log(
            `🧠 persisterFn called: ${storageKey} | cache empty: ${
                query.state.data === undefined
            } | matchesFilter: ${matchesFilter} → ${
                matchesFilter && query.state.data === undefined && storage != null
                    ? 'USE storage'
                    : "don't use storage"
            } | online: ${onlineManager.isOnline()}`
        )

        // NOTE: we are no longer trying to restore data if we are online (possible cause of bugs)
        // Try to restore only if:
        // * we are offline
        // * query matches (optional) filter
        // * we do not have any data in the cache
        // * we have local storage
        if (!onlineManager.isOnline() && matchesFilter && query.state.data === undefined && storage != null) {
            try {
                const storedData = await storage.getItem(storageKey)
                if (storedData) {
                    dconsole.log(`🧠 persisterFn using Storage for: ${storageKey}`)

                    const persistedQuery = await deserialize(storedData)

                    if (persistedQuery.state.dataUpdatedAt) {
                        const queryAge = Date.now() - persistedQuery.state.dataUpdatedAt
                        const expired = queryAge > maxAge
                        const busted = persistedQuery.buster !== buster
                        if (expired || busted) {
                            await storage.removeItem(storageKey)
                        } else {
                            dconsole.log('🧠 persisterFn: setTimeout to check staleness')
                            // Just after restoring we want to get fresh data from the server if it's stale
                            setTimeout(function () {
                                // Set proper updatedAt, since resolving in the first pass overrides those values
                                query.setState({
                                    dataUpdatedAt: persistedQuery.state.dataUpdatedAt,
                                    errorUpdatedAt: persistedQuery.state.errorUpdatedAt,
                                })

                                if (query.isStale()) {
                                    dconsole.log('🧠 persisterFn: stale -> query.fetch()')
                                    // TODO: this seems to loop back into persisterFn… (with cache set)
                                    query.fetch()
                                }
                            }, 0)
                            // We must resolve the promise here, as otherwise we will have `loading` state in the app until `queryFn` resolves

                            dconsole.log(`🧠 persisterFn: returning storage data (key: ${storageKey})`)
                            return Promise.resolve(persistedQuery.state.data)
                        }
                    } else {
                        await storage.removeItem(storageKey)
                    }
                } else {
                    dconsole.log(`🧠 persisterFn NO Storage data for: ${storageKey}`)
                }
            } catch (err) {
                // if (import.meta.env.MODE === 'development') {
                console.error(err)
                console.warn(
                    'Encountered an error attempting to restore query cache from persisted location.'
                )
                // }
                await storage.removeItem(storageKey)
            }
        }

        dconsole.log(`🧠 persisterFn: queryFn() for ${storageKey}`)
        let queryFnResult
        try {
            // If we did not restore, or restoration failed - fetch
            queryFnResult = await queryFn(context)
        } catch (err) {
            // TODO: decide whether we want to prevent retries in some cases:
            dconsole.log(`🧠 persisterFn: queryFn Error`, err)
            return Promise.reject(err)
        }

        if (matchesFilter && storage != null) {
            // Persist if we have storage defined, we use timeout to get proper state to be persisted
            setTimeout(async () => {
                storage.setItem(
                    storageKey,
                    await serialize({
                        state: query.state,
                        queryKey: query.queryKey,
                        queryHash: query.queryHash,
                        buster: buster,
                    })
                )
            }, 0)
        }

        return Promise.resolve(queryFnResult)
    }
}
