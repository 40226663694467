export const Mutations = {
    PUSH_ERROR: 'μ: Push error to log',
    PUSH_WARNING: 'μ: Push warning to log',
    UPDATE_ERROR: 'μ: Update error in log',
}

export const Actions = {
    LOG_ERROR: '🪲 Log error',
    HIDE_ERROR: '🫥 Hide error',
}

const log = {
    namespaced: false,
    state: {
        errors: [],
        warnings: [],
    },
    getters: {
        errors(state) {
            return state.errors
        },
        warnings(state) {
            return state.warnings
        },
    },
    mutations: {
        [Mutations.PUSH_ERROR](state, error) {
            state.errors.push(error)
        },
        [Mutations.PUSH_WARNING](state, warning) {
            state.warnings.push(warning)
        },
        [Mutations.UPDATE_ERROR](state, error) {
            state.errors = state.errors.map((e) => {
                if (e.id === error.id) {
                    return { ...e, ...error }
                }
                return e
            })
        },
    },
    actions: {
        [Actions.LOG_ERROR](context, { error, errorType = 'error', debugContext = null }) {
            // TODO: make console log optional?
            if (errorType === 'warning') console.warn('[Warning log]', error)
            else console.error('[Error log]', error)
            // Give unique ID to error
            error.id = `${Date.now()}.${Math.random()}`
            error.type = errorType
            if (debugContext) {
                error.debug = error.debug || {}
                error.debug.context = { ...(error.debug.context || {}), ...debugContext }
            }
            context.commit(Mutations.PUSH_ERROR, error)
        },
        [Actions.HIDE_ERROR](context, errorId) {
            context.commit(Mutations.UPDATE_ERROR, { id: errorId, hidden: true })
        },
    },
}

export default log
