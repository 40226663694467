import { inject } from 'vue'

import Login from '@/components/Login.vue'
import SSOLogin from '@/components/SSOLogin.vue'
import FAQ from '@/components/support/FAQ.vue'
import Admin from '@/shared/components/Admin.vue'
import AppDashboard from '@/components/AppDashboard/AppDashboard.vue'
import Dashboard from '@/components/Dashboard/Dashboard.vue'
import CronofyCallback from '@/components/CronofyCallback.vue'
import Configuration from '@/components/Configuration.vue'

import valuationRoutes from '@/apps/Valuation/routes'
import ersRoutes from '@/apps/ERS/routes'
import oepcRoutes from '@/apps/OEPC/routes'
import store from '@/store'
import { Actions } from '@/store/Log/index.js'

const routes = [
    {
        path: '/',
        name: 'appDashboard',
        component: AppDashboard,
        beforeEnter: (to, _from, next) => {
            if (to.redirectedFrom?.name === 'not_found') {
                // TODO: add 404 message string to i18n:
                store.dispatch(Actions.LOG_ERROR, {
                    error: {
                        error_code: 'route_not_found',
                        debug: {
                            message: `Route not found: ${to.fullPath}`,
                        },
                    },
                    errorType: 'warning',
                })
            }

            // NOTE: cannot use inject here (not yet available before App component is mounted):
            const curUser = store.getters.getCurUser
            if (curUser.availableApps.length === 0) next({ name: 'dashboard', params: to.params })
            else if (curUser.availableApps.length === 1) {
                next({ name: curUser.availableApps[0], params: to.params, query: { resetCurRequest: true } })
            } else next()
        },
        meta: {
            resetCurRequest: true,
        },
    },
    {
        path: '/default',
        redirect: '/',
    },
    {
        path: '/be-EN',
        redirect: '/en-BE', // This is needed because the next one expects a route (doesn't catch /be-EN/)
    },
    {
        path: '/be-EN/:route',
        // redirect: '/en-BE/:route',
        redirect: (to) => {
            return `/en-BE/${to.params.route}`
        },
    },
    {
        path: '/be-NL',
        redirect: '/nl-BE',
    },
    {
        path: '/be-NL/:route',
        // redirect: '/nl-BE/:route',
        redirect: (to) => {
            return `/nl-BE/${to.params.route}`
        },
    },
    {
        path: '/be-FR',
        redirect: '/fr-BE',
    },
    {
        path: '/be-FR/:route',
        // redirect: '/fr-BE/:route',
        redirect: (to) => {
            return `/fr-BE/${to.params.route}`
        },
    },
    {
        name: 'login',
        component: Login,
        path: '/login',
        alias: '/default/login',
        beforeEnter: (_to, _from, next) => {
            const config = inject('config')
            if (config.CUSTOMER_SSO_ENABLED) {
                return next({ name: 'sso_login' })
            }
            return next()
        },
    },
    {
        name: 'sso_login',
        component: SSOLogin,
        path: '/sso_login',
    },
    {
        name: 'support',
        component: FAQ,
        path: '/support',
        // beforeEnter: (to, from, next) => {
        //     // TODO: remove this and rely on ref stored in the store
        //     if (from.query.valuation_request_ref) {
        //         return next({
        //             ...to,
        //             query: {
        //                 ...to.query,
        //                 valuation_request_ref: from.query.valuation_request_ref,
        //             },
        //         })
        //     }
        //     return next()
        // },
    },
    {
        name: 'admin',
        component: Admin,
        path: '/admin',
        beforeEnter: (to, _, next) => {
            const config = inject('config')
            // NOTE: cannot use inject here (not yet available before App component is mounted):
            const curUser = store.getters.getCurUser
            if (config.CHECK_LOGIN && !curUser.is.admin)
                return next({ name: 'dashboard', params: { lang: to.params.lang } })
            return next()
        },
        meta: {
            resetCurRequest: true,
        },
    },
    {
        name: 'configuration',
        component: Configuration,
        path: '/configuration',
        meta: {
            resetCurRequest: true,
        },
    },
    {
        name: 'dashboard',
        component: Dashboard,
        path: '/dashboard',
        meta: {
            resetCurRequest: true,
        },
    },
    {
        path: '/cronofy-callback',
        name: 'cronofy-callback',
        component: CronofyCallback,
    },
    {
        path: '/invalid',
        name: 'invalid',
        component: () => import('@/components/Invalid.vue'),
    },
    ...valuationRoutes,
    ...ersRoutes,
    ...oepcRoutes,
    {
        path: '/default/:route',
        redirect: (to) => {
            console.debug('redirecting to', to.params.route)
            return `/${to.params.route}`
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not_found',
        redirect: '/',
    },
]

// Add debug and playground routes if in development mode:
// if (import.meta.env.MODE === 'development') {
routes.push({
    path: '/debug',
    name: 'debug',
    component: () => import('@/components/Debug.vue'),
})
routes.push({
    path: '/playground',
    name: 'playground',
    component: () => import('@/components/Playground.vue'),
})
routes.push({
    path: '/print-report',
    name: 'print-report',
    component: () => import('@/components/PrintReport.vue'),
})
// }

export default routes
