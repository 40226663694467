<template>
    <q-badge v-bind="$attrs" :color="`${props.color}-bground`" :text-color="props.color">
        <component
            :is="props.tag"
            class="q-pa-xs"
            :class="{
                'break-word': $attrs.multiLine !== undefined,
            }"
        >
            <slot />
        </component>
    </q-badge>
</template>

<script setup>
const props = defineProps({
    color: {
        type: String,
        default: 'secondary',
    },
    tag: {
        type: String,
        default: 'span',
    },
})
</script>

<style lang="scss" scoped>
.break-word {
    word-break: break-word;
}
</style>
