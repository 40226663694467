<template>
    <re-field
        :class="{ [`re-${props.size}`]: props.size, 're-select': true }"
        :label="label"
        :tooltip="tooltip"
        no-q-field
    >
        <q-select
            ref="inputRef"
            v-model="vModelInput"
            outlined
            :options="formatedOptions"
            map-options
            emit-value
            class="re-select"
            v-bind="$attrs"
            :display-value="defaultSelectedValue"
            :dense="$attrs.size || ['md', 'sm', 'xs'].includes(props.size)"
            :rules="[props.required && reRules.required, ...props.rules]"
        />
    </re-field>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { computed, ref, inject, watch } from 'vue'
import reRules from '@/shared/plugins/rules'

const { t, te } = useI18n()

let [vModelIn] = defineModel({ type: [String, Number, Array] })

const props = defineProps({
    tooltip: {
        type: String,
        default: null,
    },
    label: {
        type: [String, Boolean],
        default: false,
    },
    fieldKey: {
        type: String,
        default: undefined,
    },
    displaySelectedValue: {
        type: Boolean,
        default: true,
    },
    options: {
        type: Array,
        required: true,
    },
    size: {
        type: String,
        default: 'lg',
    },
    color: {
        type: String,
        default: undefined,
    },
    required: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: Array,
        default: () => [],
    },
})

const label = props.label ? props.label : props.fieldKey && te(props.fieldKey) ? t(props.fieldKey) : null
const inputRef = ref(null)
const interactedWith = ref(false)
const hasError = computed(() => (interactedWith.value ? inputRef.value?.hasError : undefined))
const validate = (val) => inputRef.value?.validate(val)
const cancel = () => (vModelInput.value = vModelIn.value)

defineExpose({ hasError, validate, cancel })

let vModel = props.fieldKey
    ? inject('curRequest').getReactive(props.fieldKey, props.reactiveOptions)
    : vModelIn

let vModelInner = ref(vModel.value)
watch(vModel, (val) => {
    vModelInner.value = val
})

const vModelInput = computed({
    get: () => vModelInner.value,
    set: (val) => {
        vModelInner.value = val
        if (validate(val)) vModel.value = val
    },
})

const formatedOptions = computed(() => {
    return props.options.map((item) => {
        if (item.label !== undefined && item.value !== undefined) return item
        return {
            label: item,
            value: item,
        }
    })
})

const defaultSelectedValue = computed(() => {
    if (props.displaySelectedValue) {
        if (vModelInput.value === null || vModelInput.value === undefined) return t('common.select_one')
        const selectedOpt = formatedOptions.value.find((el) => el.value == vModelInput.value)
        return selectedOpt?.label || selectedOpt?.value || selectedOpt || t('common.select_one')
    } else {
        return null
    }
})
</script>
<style lang="scss" scoped>
.re-select {
    :deep(.q-field__control) {
        background-color: white;
    }

    :deep(.q-field--disabled .q-field__control),
    :deep(.q-field--readonly .q-field__control) {
        background-color: $disabled-color !important;
    }

    :deep(.q-field__marginal) {
        height: inherit !important;
    }

    :deep(.q-field--auto-height .q-field__control),
    :deep(.q-field--auto-height .q-field__native) {
        min-height: 2.858em !important;
    }

    // TODO: find and remove code setting line-height to 18px somewhere
    :deep(textarea) {
        line-height: normal;
        padding-bottom: 0.75em;
    }
}
</style>
