import { computed, reactive } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import store from '@/store'

export default function useStreeView(coords) {
    const isEnabled = computed(
        () =>
            !!coords.value.lat && !!coords.value.lng && store.getters.getConfig.GOOGLE_MAP_KEY !== 'disabled'
    )

    const queryKey = computed(() => ['meta', 'streetview', { lat: coords.value.lat, lng: coords.value.lng }])

    const query = useQuery({
        queryKey: queryKey,
        placeholderData: {},
        select: (data) => {
            return {
                gsv_available: data.status === 'OK',
                gsv_ph: data.heading || null,
                gsv_pp: 0,
                gsv_pz: 1,
                gsv_p: data.pano_id || null,
                gsv_lat: data.lat || null,
                gsv_lng: data.lng || null,
            }
        },
        enabled: isEnabled,
    })

    return reactive({
        queryKey,
        ...query,
    })
}
