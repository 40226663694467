import { i18n } from '@/i18n'
import PostalCodes from './postal_codes.json'
import { decimal, email } from '@vuelidate/validators'
import store from '@/store'

const t = i18n.global.t
const rules = {}

rules.required = (x) =>
    (x !== undefined && x !== null && (x.length === undefined || x.length > 0)) ||
    t('monolith.validation.required')

rules.numeric = (x) => decimal.$validator(x) || t('validation.only_numbers')
// TODO: pick a lane:
rules.decimal = rules.numeric
// TODO: add i18n for email validation:
rules.email = (x) => email.$validator(x) || email.$message

rules.minValue = (min) => (x) => {
    // TODO: error message should mention the min value
    return min === undefined || min === null || x >= min || t('validation.value_too_small', { min })
}

rules.maxValue = (max) => (x) => {
    // TODO: error message should mention the max value
    return max === undefined || max === null || x <= max || t('validation.value_too_large', { max })
}

rules.consecutive = (val = []) => {
    const array = [...val].sort((a, b) => a - b)
    let is_consecutive = true
    for (let i = 1; i < array.length; i++) {
        is_consecutive = is_consecutive && array[i - 1] === array[i] - 1
    }

    return is_consecutive || t('monolith.validation.floors_not_consecutive')
}

rules.between = (min, max) => (x) => {
    return (
        !x ||
        (x >= min && x <= max) ||
        t('validation.range', {
            min: min,
            max: max,
        })
    )
}

rules.betweenYears = (minYear, maxYear) => (x) => {
    return (
        !x ||
        (x >= minYear && x <= maxYear) ||
        t('validation.valid_years', {
            min: minYear,
            max: maxYear,
        })
    )
}

rules.mustBeCoherent = (feature, dvmRejections) => {
    if (!dvmRejections.length) return true
    const features = dvmRejections.at(-1).incoherent_features
    if (features === undefined) return true
    const value = features[feature]
    if (value === undefined) return true
    return (x) => {
        return x !== value || t('monolith.validation.incoherent_feature')
    }
}

// TODO: need generic error message
rules.oneOf =
    (values, msg = undefined) =>
    (x) =>
        values.includes(x) || msg || t('validation.invalid_value', { values })

rules.postalcode = (x) => PostalCodes.includes(Number(x)) === true || `This postal code doesn't exists`

rules.minLength4 = (x) => x.length >= 4 || t('validation.min_4_characters')
rules.maxLength = (x) => {
    const maxLength = store.getters.getConfig.CUSTOMER_REF_MAX_LENGTH
    return !maxLength || maxLength >= String(x).length || t('valuation.request.text_too_long')
}

rules.numericValidation = (x) => {
    if (store.getters.getConfig.CUSTOMER_REF_NUMERIC) {
        return rules.numeric(x)
    } else {
        return true
    }
}

rules.forbiddenSubstring = (x) => {
    // ING specific forbidden substring
    if (store.getters.getConfig.CUSTOMER_REF_FORBIDDEN_SUBSTRING) {
        return !x.includes('0000000') || t('valuation.enter.ing.forbidden_substring')
    } else {
        return true
    }
}
//
export default rules
