<template>
    <re-dialog :title="$t('global.helpcenter.contact_form.title')">
        <q-form ref="form" class="column full-width">
            <p>
                {{ $t('global.helpcenter.contact_form.content') }}
            </p>
            <re-select
                v-model="contactForm.module"
                name="module"
                :label="$t('common.module')"
                :readonly="!!module"
                :options="[
                    ...curUser.accessibleModules.map((m) => ({
                        label: $t(`value.${m}`),
                        value: m,
                    })),
                    {
                        label: $t(`monolith.shared.na`),
                        value: '-',
                    },
                ]"
                map-options
                emit-value
            />
            <re-select
                v-model="contactForm.subject"
                name="subject_tech"
                map-options
                emit-value
                :readonly="subjects.length <= 1"
                :label="$t('global.helpcenter.contact_form.subject_label')"
                :options="
                    subjects.map((subject) => ({
                        value: subject,
                        label: $t(`global.helpcenter.contact_form.subject_${subject}`),
                    }))
                "
            />
            <re-input
                v-if="contactForm.subject === 'other'"
                v-model="contactForm.otherSubject"
                :label="$t('global.helpcenter.contact_form.subject_other')"
            />
            <re-select
                v-model="contactForm.valuation_request_ref"
                name="valuation_request_ref"
                map-options
                emit-value
                :readonly="!!valuationRequestRef"
                :label="$t('global.helpcenter.contact_form.customer_ref')"
                :options="[
                    ...userRequests.map((r) => ({
                        value: r.valuation_request_ref,
                        label: `${r.customer_ref} - ${display_address(r)}`,
                    })),
                    { label: '-------', disable: true, value: '-------' },
                    { value: 'N/A', label: $t('global.helpcenter.contact_form.not_specific_req') },
                ]"
            />
            <re-input
                v-model="contactForm.content"
                :label="$t('global.helpcenter.contact_form.message')"
                type="textarea"
                class="full-width"
                name="content"
            />
            <q-file
                v-model="contactForm.screenshots"
                multiple
                append
                :label="$t('global.helpcenter.contact_form.screenshot_label')"
                class="full-width"
                accept="image/png, image/jpeg"
                filled
                use-chips
                :filter="checkFile"
                @rejected="onRejected"
            />
            <p class="text-caption">{{ $t('global.helpcenter.contact_form.screenshot_info') }}</p>
            <div class="row items-start justify-center full-width">
                <q-img
                    v-for="(screenshot, i) in screenshots"
                    :key="i"
                    :src="screenshot"
                    class="col-4 q-ma-sm"
                />
            </div>
        </q-form>
        <template #actions>
            <re-button
                v-close-popup="3"
                type="submit"
                color="main"
                padding="sm xl"
                size="md"
                class="self-end"
                :disable="!contactForm.valuation_request_ref"
                :label="$t('global.helpcenter.contact_form.submit')"
                @click="sendFeedback"
            />
        </template>
    </re-dialog>
</template>

<script>
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios'
import enums from '@/shared/plugins/enums'
import { useQuasar } from 'quasar'

export default {
    name: 'ContactForm',
    inject: ['curUser'],
    props: {
        module: {
            type: String,
            default: null,
        },
        subject: {
            type: String,
            default: null,
        },
        valuationRequestRef: {
            type: String,
            default: null,
        },
    },
    emits: ['hide-contact-modal'],
    setup() {
        return {
            q: useQuasar(),
        }
    },
    data() {
        return {
            searchField: '',
            contactForm: {
                subject: this.subject,
                module: this.module,
                valuation_request_ref: this.valuationRequestRef,
                otherSubject: '',
                content: '',
                screenshots: [],
            },

            userRequests: [],
        }
    },
    computed: {
        maxSize() {
            return this.$config.MIN_FILE_SIZE || enums.defaultConfig.fileMaxSize
        },
        screenshots() {
            return this.contactForm.screenshots.map((file) => URL.createObjectURL(file))
        },
        subjects() {
            const selectedModule = this.contactForm.module
            if (selectedModule === 'ers') {
                return [
                    'req-failing',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            } else if (selectedModule === 'dvm') {
                return [
                    'req-not-eval-yet',
                    'req-back-to-draft',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            } else if (selectedModule === 'oepc') {
                return ['req-custom-quote']
            } else if (selectedModule === '-' || selectedModule === '') {
                return [
                    'req-not-eval-yet',
                    'req-back-to-draft',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            }
            return []
        },
    },
    mounted() {
        this.getRequests()
        if (this.$route.query.from_module) {
            this.contactForm.module = this.$route.query.from_module
        }
    },
    methods: {
        checkFile(files) {
            // TODO a file that have a size a litle bit greater than 2Mo can be uploaded.
            // fix: change the backend value to 3Mo ?
            return files.filter((file) => file.size < this.maxSize)
        },
        onRejected() {
            this.q.notify({
                type: 'negative',
                message: this.$t('monolith.validation.file_too_big', {
                    max_size: Math.round(this.maxSize / 1048576),
                }),
            })
        },
        getRequests() {
            axios.get(utils.urlJoin(this.$config.VALUATION_API_URL, ['user', 'requests'])).then((res) => {
                this.userRequests = res.data
            })
        },
        display_address(el) {
            let a = utils.full_address(el.address, el.features)
            if (utils.via_address(el.address, el.features) !== '') {
                a = `${a} (via ${utils.via_address(el.address, el.features)})`
            }
            return a
        },

        removeScreenshot(index) {
            this.contactForm.screenshots.splice(index, 1)
        },
        resetContactForm() {
            this.contactForm = {
                subject: '',
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            }
        },
        sendFeedback() {
            const { otherSubject, screenshots, subject, module, content, valuation_request_ref } =
                this.contactForm
            // TODO : Replace below commented API call to the proper endpoint and
            // remove the lines above this comment block and below the commented
            // API call

            const formData = new FormData()
            if (subject === 'other') {
                formData.append('subject', otherSubject)
            } else {
                formData.append('subject', this.$t(`global.helpcenter.contact_form.subject_${subject}`))
            }
            formData.append('module', module)
            formData.append('content', content)
            formData.append('to', 'support')
            if (valuation_request_ref !== 'N/A')
                formData.append('valuation_request_ref', valuation_request_ref)
            formData.append(
                'url',
                JSON.stringify({
                    params: this.$route.params,
                    fullPath: this.$route.fullPath,
                    query: this.$route.query,
                    hash: this.$route.hash,
                    name: this.$route.name,
                })
            )
            for (let i = 0; i < screenshots.length; i++) {
                formData.append(`screenshot_${i}`, screenshots[i])
            }
            axios
                .post(utils.urlJoin(this.$config.VALUATION_API_URL, 'contact'), formData, {
                    headers: { 'content-type': 'multipart/form-data' },
                })
                .then(() => {
                    this.q.notify({
                        type: 'positive',
                        message: `${this.$t('global.helpcenter.contact_form.feedback_title')}, ${this.$t('global.helpcenter.contact_form.feedback_content')}`,
                    })
                    this.resetContactForm()
                })
        },
    },
}
</script>
