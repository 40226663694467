import { unref, computed, watch } from 'vue'

/**
 * When any of the given refs is true, the result is true and reactive.
 * @param  {...import('vue').MaybeRef<boolean>} maybeRefs
 * @returns
 */
export function reactiveAny(...maybeRefs) {
    return computed(() => {
        return maybeRefs.map((maybeRef) => unref(maybeRef)).some((bool) => bool)
    })
}

export function getOnLoadAll(refs, testFn = (x) => !!x) {
    return (callback) => {
        if (refs.every((x) => testFn(unref(x)))) {
            callback(refs.map(unref))
        } else {
            const unwatch = watch(
                refs,
                function () {
                    if (refs.every((x) => testFn(unref(x)))) {
                        callback(refs.map(unref))
                        unwatch()
                    }
                },
                { deep: true }
            )
        }
    }
}

export function getOnLoad(singleRef, testFn = (x) => !!x) {
    return (callback) => getOnLoadAll([singleRef], testFn)((vals) => callback(vals[0]))
}
