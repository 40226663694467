<template>
    <component :is="imgComponent" />
</template>
<script setup>
import { defineAsyncComponent } from 'vue'

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    dir: {
        type: String,
        default: 'icons',
    },

    subdir: {
        type: String,
        default: null,
    },
})

const imgComponent = defineAsyncComponent(() =>
    props.dir === 'icons'
        ? props.subdir
            ? import(`@/assets/icons/${props.subdir}/${props.name}.svg?component`)
            : import(`@/assets/icons/${props.name}.svg?component`)
        : props.subdir
          ? import(`@/assets/img/${props.dir}/${props.subdir}/${props.name}.svg?component`)
          : import(`@/assets/img/${props.dir}/${props.name}.svg?component`)
)
</script>
