<template>
    <div id="vue-app" class="app-container" :class="{ offline: !isOnline }">
        <q-layout view="hHh LPR lFf">
            <q-header :reveal="$q.screen.lg.sm">
                <navbar
                    ref="navbar"
                    v-model:menu-open="isMobileMenuOpen"
                    :has-side-menu="canShowSideMenu"
                    :fixed="$route.name === 'report'"
                ></navbar>
            </q-header>
            <sidemenu v-if="canShowSideMenu" v-model:menu-open="isMobileMenuOpen" class="app-sidemenu" />
            <q-page-container class="app-content">
                <router-view />
                <div class="errors-container print-hide">
                    <Errors />
                    <Errors error-type="warning" />
                </div>
                <hubspot-chat v-if="$config.ENABLE_HUBSPOT" />
            </q-page-container>
        </q-layout>
    </div>
</template>

<script>
import { provide } from 'vue'

import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

import Sidemenu from '@/components/common/Sidemenu/Sidemenu.vue'
import Navbar from '@/components/common/Navbar.vue'
import HubspotChat from '@/components/HubspotChat.vue'
import Errors from '@/components/Errors.vue'

import { useOnline } from '@/composables/utils/online'

import { submitStoredMutations, SUBMIT_STORE_MUTATION_PREFIX } from '@/composables/query'
import { onlineManager, useMutation } from '@tanstack/vue-query'

import useRequest from '@/composables/request'
import useAuthUser from '@/composables/authUser'
import store from '@/store'
import { Actions } from '@/store/Auth'
import { useHead } from '@unhead/vue'

export default {
    components: {
        Navbar,
        Sidemenu,
        HubspotChat,
        Errors,
    },
    setup() {
        const { isOnline } = useOnline()

        const { mutateAsync } = useMutation({
            mutationKey: [SUBMIT_STORE_MUTATION_PREFIX],
        })

        // Submit stored mutations on first load, then whenever we come back online:
        // console.debug('💡 app init: submitting stored mutations')
        submitStoredMutations(mutateAsync)
        onlineManager.subscribe(function onOnline(_isOnline) {
            if (_isOnline) {
                // console.debug('💡 app went online: submitting stored mutations')
                submitStoredMutations(mutateAsync)
            }
        })

        const curUser = useAuthUser()
        provide('curUser', curUser)
        // NOTE: some parts of the app (eg Router guards) do not have access to vars provided at this level
        // so we also keep a copy of in the store:
        store.dispatch(Actions.STORE_AUTH_USER, curUser)

        const curRequest = useRequest()
        provide('curRequest', curRequest)

        console.log(
            `🔥 app init: setting up auth user (${store.getters.getUsername}) and current request (${curRequest.requestRef})`
        )

        useHead({
            title: 'Rock.estate App',
            titleTemplate: '%s | Rockestate estimator',
        })

        return { isOnline, curUser }
    },
    data() {
        return {
            //     countdown: null,
            //     countdownInterval: null,
            //     isMenuMini: true,
            isMobileMenuOpen: false,
            testInput: null,
        }
    },
    computed: {
        hasWideSide() {
            return ['appDashboard'].includes(this.$route.name)
        },
        canShowSideMenu() {
            return (
                (!['login'].includes(this.$route.name) &&
                    !(this.$route.meta?.public || this.curUser.authenticated) &&
                    !this.$config.CHECK_LOGIN) ||
                store.state.auth.authenticated
            )
            // !this.curUser?.hasRole('borrower', 'ovm', true)
        },
    },
    mounted() {
        console.log('Rockestate app ' + import.meta.env.VITE_APP_VERSION)

        // TODO: do we still need this?
        // window.addEventListener('resize', this.onResize)

        // TODO: can we move this somewhere else? eg when doing main auth check?
        // TODO: alternatively: use a dedicated endpoint instead of /user?
        // Check that the frontend verion matches what the backend thinks it should be
        // If it doesn't, refresh with the url containing the version number
        // This should defeat aggressive corporate caches.
        if (this.$config.AUTOREFRESH_STALE_FRONTEND && this.isOnline) {
            axios
                .get(utils.urlJoin(this.$config.AUTH_API_URL, 'user'), {
                    params: { frontend_version: import.meta.env.VITE_APP_VERSION_FULL },
                })
                .then(function (response) {
                    let frontend_version = response.data.frontend_version
                    if (
                        frontend_version &&
                        // Using numeric localCompare to get v1.2.0-8 < v1.2.0-10 < v2 < v10

                        frontend_version.localeCompare(import.meta.env.VITE_APP_VERSION_FULL, 'en', {
                            numeric: true,
                        }) === 1
                    ) {
                        console.warn(
                            `current frontend version ${import.meta.env.VITE_APP_VERSION_FULL}` +
                                ` expecting ${frontend_version}`
                        )
                        if (window.location.toString().indexOf(frontend_version) === -1) {
                            console.warn(` ==> refreshing`)
                            window.location.replace(
                                [
                                    location.protocol,
                                    '//',
                                    location.host,
                                    location.pathname,
                                    '?v=',
                                    frontend_version,
                                ].join('') // https://stackoverflow.com/a/5817566
                            )
                        } else {
                            console.warn(' version mismatch, even after refresh')
                        }
                    }
                })
        }
    },
    // beforeUnmount() {
    //     window.removeEventListener('resize', this.onResize)
    // },
    // methods: {
    //     find_cookie(cname) {
    //         var name = cname + '='
    //         var ca = document.cookie.split(';')
    //         for (var i = 0; i < ca.length; i++) {
    //             var c = ca[i]
    //             while (c.charAt(0) == ' ') {
    //                 c = c.substring(1)
    //             }
    //             if (c.indexOf(name) == 0) {
    //                 var cookie_value = c.substring(name.length, c.length)
    //                 return cookie_value
    //             }
    //         }
    //         return null
    //     },
    //     set_cookie(cname, cvalue, exdays) {
    //         var d = new Date()
    //         d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    //         var expires = 'expires=' + d.toUTCString()
    //         document.cookie = cname + '=' + cvalue + '; ' + expires + '; Path=/;'
    //     },
    // },
}
</script>
<style lang="scss" scoped>
#vue-app {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
}

.app-container {
    @media print {
        overflow: visible;
        display: block;
    }

    .app-content {
        @media print {
            overflow: visible;
            display: block;
            padding-top: 0 !important;
            margin-top: 0;
            padding-left: 0 !important;
            margin-left: 0;
        }

        .errors-container {
            max-height: 40vh;
            width: 90%;
            overflow: scroll;
            position: fixed;
            bottom: 0;
            z-index: 1001;
        }
    }
    .app-sidemenu {
        @media print {
            display: none;
        }
    }
}
</style>
