<template>
    <!-- TODO: Use re-field to get validations -->
    <re-input :model-value="selectedDateTime?.toLocaleString()" v-bind="$attrs">
        <q-popup-proxy transition-show="scale" transition-hide="scale">
            <div class="row">
                <q-date v-model="modelWrapper" mask="x" first-day-of-week="1"></q-date>
                <q-time v-model="modelWrapper" mask="x" format24h></q-time>
            </div>
            <div class="row items-center justify-end">
                <q-btn label="Clear" color="main" flat @click="selectedDateTime = null"></q-btn>
                <q-btn v-close-popup label="Close" color="main" flat></q-btn>
            </div>
        </q-popup-proxy>
    </re-input>
</template>
<script setup>
import { computed } from 'vue'

const selectedDateTime = defineModel({
    type: [Date, null],
})

const modelWrapper = computed({
    get() {
        return selectedDateTime.value?.getTime().toString()
    },
    set(v) {
        selectedDateTime.value = new Date(Number(v))
    },
})
</script>
