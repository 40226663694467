<template>
    <q-icon
        v-bind="$attrs"
        :class="{
            'self-center': props.selfCenter,
            [`text-${props.color}`]: props.color,
            'rounded-icon': props.roundedIcon,
            [`bg-${props.color}-bground`]: props.roundedIcon && props.color,
        }"
        :name="iconName"
    >
        <q-badge v-if="indicator" floating color="red" rounded />
        <component :is="IconSrc" v-else-if="IconSrc" />
    </q-icon>
</template>
<script setup>
import { computed, defineAsyncComponent } from 'vue'

const props = defineProps({
    svgName: {
        type: String,
        required: false,
        default: undefined,
    },
    color: {
        type: String,
        required: false,
        default: undefined,
    },
    indicator: {
        type: Boolean,
        required: false,
        default: false,
    },
    roundedIcon: {
        type: Boolean,
        required: false,
        default: false,
    },
    selfCenter: {
        type: Boolean,
        required: false,
        default: true,
    },
})
const iconName = computed(() =>
    props.color === 'positive' ? 'mdi-check-bold' : props.color === 'negative' ? 'mdi-close-thick' : undefined
)

const IconSrc = props.svgName ? defineAsyncComponent(() => import(`./icons/${props.svgName}.vue`)) : undefined
</script>
<style lang="scss" scoped>
.q-badge {
    /* https://github.com/quasarframework/quasar/issues/17342 */
    min-height: 0px;
    padding: 6px;
}

.rounded-icon {
    border-radius: 50%;
    padding: 2px;
    border-width: 2px;
    border: solid;
    &.text-positive {
        border-color: $positive-light;
    }
    &.text-negative {
        border-color: $negative-light;
    }
}
</style>
