import Vuex from 'vuex'

import auth, { Mutations as authMutations, Actions as authActions } from './Auth/index.js'
import log, { Mutations as logMutations, Actions as logActions } from './Log/index.js'
import ersStore from '@/apps/ERS/store'

// TODO: use enums for mutation and action names everywhere
export const Mutations = {
    SET_CONFIG: 'μ: Set config',
    SET_UNREAD_MESSAGES: 'μ: Set unread messages',
    SET_REQUEST_REF: 'μ: Set request ref',
    RESET_REQUEST_REF: 'μ: Reset request ref',
    ...authMutations,
    ...logMutations,
}

export const Actions = {
    ...authActions,
    ...logActions,
}

const getDefaultState = () => {
    return {
        unread_messages: null,
        requestRef: null,
        config: null,
    }
}

const state = getDefaultState()

export default new Vuex.Store({
    state,
    getters: {
        hasUnreadMessages(state) {
            return !!state.unread_messages
        },
        getRequestRef(state) {
            return state.requestRef
        },
        getConfig(state) {
            return state.config
        },
    },
    mutations: {
        SET_UNREAD_MESSAGES(state, unread_messages) {
            state.unread_messages = unread_messages
        },
        SET_REQUEST_REF(state, requestRef) {
            state.requestRef = requestRef
        },
        RESET_REQUEST_REF(state) {
            state.requestRef = null
        },
        [Mutations.SET_CONFIG](state, config) {
            state.config = config
        },
    },
    modules: {
        auth,
        ersStore,
        log,
    },
})
