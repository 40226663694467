import axios from 'axios'
import store from '@/store/index.js'
import { Actions } from '@/store/Log'

// create axios instance with base Url
let baseURL = import.meta.env.VITE_APP_API_URL
if (baseURL && baseURL.endsWith('/')) {
    // Remove any trailing slash
    baseURL = baseURL.slice(0, -1)
}
const instance = baseURL ? axios.create({ baseURL: baseURL }) : axios.create()

// interceptors for request and response
// still need to find out if toast can be fired from here

instance.interceptors.request.use(
    function (config) {
        // Do something before request is sent

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger

        // Do something with response data
        return response
    },
    function (err) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        /*if (error.response.status !== 404) {
            // can update message key in store and use it in toast
            Toast.open({
                duration: 5000,
                message: `Something's not good, also I'm on <b>bottom</b>`,
                position: 'is-bottom-right',
                type: 'is-danger'
            })
            //store.commit('API_MESSAGE', error.response.data)
        }*/

        // console.warn('Axios error', err.response)

        // Queue errors and warnings in store:
        try {
            const debugContext = {
                axiosConfig: err.response?.config,
                axiosHeaders: err.response?.headers,
            }

            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.errors || err.response.data.warnings) {
                        // TODO: add specific field to response data in backend,
                        //  to identify RE error schema?
                        for (const error of err.response.data.errors || [])
                            store.dispatch(Actions.LOG_ERROR, {
                                error,
                                errorType: 'error',
                                status: err.response.status,
                                debugContext,
                            })
                        for (const error of err.response.data.warnings || [])
                            store.dispatch(Actions.LOG_ERROR, {
                                error,
                                errorType: 'warning',
                                status: err.response.status,
                                debugContext,
                            })
                    } else {
                        var error_code = err.response.data.status
                            ? `server_error_status_${err.response.data.status
                                  .replace(' ', '_')
                                  .toLowerCase()}`
                            : `unknown_server_error_${err.response.status}`

                        var debug_message = err.response.data.debug_info
                            ? err.response.data.debug_info
                            : 'Unknown server-side error: no debug info available'

                        if (
                            err.response.data.proxy_error &&
                            err.response.data.proxy_error.includes('ECONNREFUSED')
                        ) {
                            error_code = `server_connection_refused`
                            debug_message = 'Server connection failed'
                        }
                        const error = {
                            error_code: error_code,
                            debug: {
                                message: debug_message,
                                context: { raw_response: err.response.data },
                            },
                        }
                        store.dispatch(Actions.LOG_ERROR, {
                            error,
                            errorType: 'error',
                            status: err.response.status,
                            debugContext,
                        })
                    }
                } else {
                    // No data: unknown error (most likely client-side)
                    const error = {
                        error_code: 'unknown_client_error',
                        debug: {
                            message:
                                'Request error with no response data: most likely a connection issue on the client side',
                            context: err.response,
                            status: err.response.status,
                        },
                    }
                    store.dispatch(Actions.LOG_ERROR, {
                        error,
                        errorType: 'error',
                        debugContext,
                    })
                }
            } else {
                // No response: most likely code exception
                const error = {
                    error_code: 'unknown_exception',
                    debug: {
                        message: 'Request error with no response: most likely a code exception',
                        context: err,
                    },
                }
                // Should we add an errorType 'exception'?
                store.dispatch([Actions.LOG_ERROR], { error, errorType: 'error' }, debugContext)
            }
        } catch (err) {
            // Who's catching the catcher?
            if (import.meta.env.MODE === 'development') {
                throw err
            } else {
                const error = {
                    error_code: 'unknown_exception',
                    debug: {
                        message: 'Exception while handling request error: most likely a code exception',
                        context: err,
                    },
                }
                store.dispatch(Actions.LOG_ERROR, { error, errorType: 'error' })
            }
        }

        err.handled_by_axios = true
        return Promise.reject(err)
    }
)

export default instance
