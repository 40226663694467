import { computed, watch, ref, reactive } from 'vue'
import { useQuery } from '@tanstack/vue-query'
// import dconsole from '@/shared/plugins/debug_console'

import wrapRequest from './requestWrapper.js'
import { reactiveAny } from './utils/reactive.js'

export default function ({ isUserRequests: initIsUserRequests } = { isUserRequests: false }) {
    // Clean up filtering: should really only have one filter + mode (user/all)

    const isUserRequests = ref(initIsUserRequests)
    const timeFilter = ref('last_month')
    const valReqsFilter = ref(null)
    const customerRefFilter = ref(null)

    const requestQueryParams = computed(() => {
        if (valReqsFilter.value)
            return {
                valuation_request_refs: valReqsFilter.value,
                load_all: true,
            }
        if (customerRefFilter.value)
            return {
                customer_ref: customerRefFilter.value,
            }
        if (!timeFilter.value) return null

        const days_delta = timeFilter.value === 'last_month' ? 30 : 365
        const [modified_after] = new Date(new Date().setDate(new Date().getDate() - days_delta))
            .toISOString()
            .split('T')
        return {
            modified_after,
        }
    })

    // watch(isUserRequests, () => {
    //     // console.debug('isUserRequests changed', isUserRequests.value, queryKey.value)
    //     valReqsFilter.value = null
    // })
    watch(timeFilter, () => {
        // console.debug('timeFilter changed', timeFilter.value, queryKey.value)
        valReqsFilter.value = null
    })

    const queryKey = computed(() => [
        'v2',
        'valuation',
        ...(isUserRequests.value ? ['user'] : []),
        'request',
        requestQueryParams.value,
    ])

    const requestQuery = useQuery({
        queryKey,
        select: (data) => {
            // console.debug('📋 requests select: wrapping requests:', data)
            return data.map(wrapRequest).filter((request) => !request.error)
        },
        placeholderData: [],
        // enabled: computed(() => !isUserRequests.value),
    })

    const onLoad = function (callback) {
        if (requestQuery.isFetched.value) {
            callback(requestQuery.data.value)
        } else {
            const unwatch = watch(requestQuery.isFetched, function () {
                if (requestQuery.isFetched.value) {
                    callback(requestQuery.data.value)
                    unwatch()
                }
            })
        }
    }

    // const userRequests = useQuery({
    //     queryKey: ['valuation', 'user', 'requests', requestsQuery],
    //     select: (data) => {
    //         return data.map(wrapRequest).filter((request) => !request.error)
    //     },
    //     placeholderData: [],
    //     enabled: computed(() => isUserRequests.value),
    // })
    // const filteredRequests = useQuery({
    //     queryKey: ['valuation', 'requests', requestFilter.value],
    //     select: (data) => {
    //         return data.map(wrapRequest).filter((request) => !request.error)
    //     },
    //     placeholderData: [],
    //     enabled: computed(() => !requestFilter.value),
    // })

    // const queryClient = useQueryClient()

    // Invalidate stale valuation requests based on modified_at
    // TODO: same with allUserRequests
    // const userRequestsData = computed(() => userRequests.data)
    // watch(userRequestsData, (requests) => {
    //     for (const request of requests) {
    //         const queryKey = ['valuation', 'request', request.valuation_request_ref, { details: 'full' }]
    //         const request_data = queryClient.getQueryData(queryKey)
    //         if (request_data && new Date(request_data.modified_at) < request.modified_at) {
    //             queryClient.invalidateQueries({
    //                 queryKey,
    //             })
    //             // dconsole.log('request fetched but stale -> invalidating', {
    //             //     queryKey,
    //             // })
    //         } else {
    //             // if (!request_data) {
    //             //     dconsole.log('request not fetched -> fetching', { queryKey })
    //             // } else {
    //             //     dconsole.log('request fetched, still valid', { queryKey })
    //             // }
    //         }
    //     }
    // })

    // Todo: see if this should be
    //   - turned into multiple queryClient.prefechtQuery()
    //   - moved into the watcher
    // const fullRequestQueries = useQueries({
    //     queries: computed(() =>
    //         userRequests.value
    //             ? userRequests.value.map(({ valuation_request_ref }) => {
    //                   return {
    //                       queryKey: [
    //                           'valuation',
    //                           'request',
    //                           valuation_request_ref,
    //                           { details: 'full' },
    //                       ],
    //                       staleTime: Infinity,
    //                   }
    //               })
    //             : []
    //     ),
    // })
    // const isLoading = computed(
    //     () => re.isLoading || allRequests.isLoading || filteredRequests.isLoading
    // )
    // const isFetching = computed(
    //     () => userRequests.isFetching || allRequests.isFetching || filteredRequests.isFetching
    // )

    return reactive({
        timeFilter,
        valReqsFilter,
        customerRefFilter,
        isUserRequests,
        queryKey,
        onLoad,
        ...requestQuery,
        // fullRequestQueries,
        // allRequests,
        // userRequests,
        // filteredRequests,
        // isLoading,
        // isFetching,
        // isError: computed(() => userRequests.isError || allRequests.isError || filteredRequests.isError),
        isBusy: reactiveAny(requestQuery.isLoading, requestQuery.isFetching),
    })
}
