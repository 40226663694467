<template>
    <div v-if="!errorsHidden && filteredErrors.length" class="errors-block q-pa-md">
        <div :class="`${errorType}s`">
            <re-banner
                v-for="error in filteredErrors"
                :key="error.id"
                :color="errorType == 'warning' ? 'warning' : 'negative'"
                :class="[`re-${errorType}`, `re-${errorType}-${error.message}`, 'q-mt-sm']"
                :title="errorTitle(error)"
            >
                <p v-if="errorMsg(error)">{{ errorMsg(error) }}</p>
                <p v-else>
                    <strong>[{{ errorCode(error) }}]</strong>
                    {{ error.debug?.message }}
                </p>
                <details v-if="error.debug && isDev">
                    <summary>Debug info</summary>
                    <p>{{ error.debug.message }}</p>
                    <ul v-if="error.debug.context">
                        <li v-for="(value, key) in error.debug.context" :key="key">
                            <strong>{{ key }}{{ $t('punctuation.:') }}</strong>
                            {{ value }}
                        </li>
                    </ul>
                    <pre v-if="error.debug.traceback">{{ error.debug.traceback }}</pre>
                </details>
                <template #action>
                    <re-button
                        icon="mdi-close"
                        dense
                        rounded
                        size="sm"
                        color="white"
                        @click="closeError(error)"
                    />
                </template>
            </re-banner>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Actions } from '@/store/Log'

export default {
    name: 'Errors',
    props: {
        errorType: {
            type: String,
            default: 'error',
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['errors', 'warnings']),
        isDev: () => import.meta.env.MODE === 'development',
        errorsHidden: function () {
            return !!this.$config.HIDE_FRONTEND_ERRORS
        },
        filteredErrors() {
            return (this.errors || []).filter((e) => !e.hidden && e.type === this.errorType)
        },
    },
    mounted() {
        // TODO: add (silent) exception handling for this template, to prevent infinite loops
    },
    methods: {
        closeError(error) {
            this.$store.dispatch(Actions.HIDE_ERROR, error.id)
        },
        errorI18nKey(error) {
            return `${this.errorType}.${this.errorCode(error)}`
        },
        errorTitle(error) {
            // localised error title > [localised] error code > english title > [localised] error type

            if (error.title && this.$te(error.title)) return this.$t(error.title)

            const titleKey = `${this.errorI18nKey(error)}.title`
            if (this.$te(titleKey)) return this.$t(titleKey)

            if (error.title) return error.title

            const title = `${this.errorType.charAt(0).toUpperCase() + this.errorType.slice(1)}`
            return this.$te(title) ? this.$t(title) : title
        },
        errorMsg(error) {
            const msgKey = `${this.errorI18nKey(error)}.message`
            return this.$te(msgKey) ? this.$t(msgKey, error.details) : false
        },
        errorCode(error) {
            return error.error_code !== undefined ? error.error_code : error.message
        },
    },
}
</script>

<style lang="scss" scoped>
pre {
    white-space: pre-wrap;
}
</style>
